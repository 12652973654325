/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-08-18 08:33:56
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:31:05
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//优惠券配置查询单条
export const GetCouponSingle=(params)=>{
    return request.apiPost(`/Api${API_VERSION}/Sales/SalesCoupon/Get`,params)
}
//优惠券配置查询
export const GetPage=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Sales/SalesCoupon/GetPage`,params)
}

//优惠券配置添加
export const Add=(params)=>{
    return request.apiPost(`/api${API_VERSION}/Sales/SalesCoupon/Add`,params)
}
//优惠券配置修改
export const Update=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Sales/SalesCoupon/Update`,params)
}
//优惠券配置删除
export const SoftDelete=(params)=>{
    return request.apiDelete(`/api${API_VERSION}/Sales/SalesCoupon/SoftDelete?CouponId=`+params)
}

//优惠券配置批量删除
export const BatchSoftDelete=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Sales/SalesCoupon/BatchSoftDelete`,params)
}