<!--
 * @Description: 
 * @Author: 优
 * @Date: 2021-08-16 09:47:11
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:19:25
-->
<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="Flag" firstCrumbs="营销管理" secondCrumbs="优惠券配置" />
    <!-- 面包屑 end -->
    <!-- 内容区域 begin -->
    <div class="container">
      <div class="handle-box">
        <template v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:getpage`,`api${state.VIEW_VERSION}:sales:salescoupon:add`,`api${state.VIEW_VERSION}:sales:salescoupon:batchsoftdelete`])">
            <el-form ref="state.query"  :inline="true" >
               <template v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:getpage`])">
                 <el-form-item label="优惠券名称">
                <el-input
              v-model="state.couponName"
              placeholder="优惠券名称"
              
            ></el-input>
          </el-form-item>
           <el-form-item label="优惠券状态">
                   <el-select v-model="state.query.isActive" filterable placeholder="优惠券状态"  class="handle-select mr10">
                      <el-option v-for="item in state.queryOpisActive" :key="item.isActive" :label="item.label" :value="item.isActive"></el-option>
                  </el-select>
                </el-form-item>
               <el-form-item el-form-item style="margin-right:10px">
                <el-button type="primary" icon="Search" @click="handleSearch"
              >搜索</el-button>
            </el-form-item>
          </template>
                
            <el-form-item el-form-item style="margin-right:10px">
                <el-button
            type="primary"
            icon="Plus"
            @click="handleClickAddData"
            v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:add`])"
            >新增</el-button>
            </el-form-item>
            <el-form-item el-form-item style="margin-right:10px">
                 <el-button
            type="danger"
            icon="Delete"
            class="handle-del mr10"
            @click="handleClickBatchDelete()"
            v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:batchsoftdelete`])"
            >批量删除</el-button>
            </el-form-item>
            </el-form>
        </template>
      </div>
      <el-table
        :data="state.tableData"
        border
        highlight-current-row
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
        v-loading="state.loading">
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="couponName" label="优惠券名称" min-width="100"  align="center" ></el-table-column>
        <el-table-column prop="couponContent" label="优惠券金额" min-width="100" align="center"></el-table-column>
        <el-table-column prop="publishCount" label="发行数量"  min-width="90" align="center"></el-table-column>
        <el-table-column prop="isActive" label="优惠券状态" min-width="100" align="center" :formatter="hasValidFormaStatus"></el-table-column>
        <el-table-column prop="couponType"  label="优惠券类型" min-width="100" align="center"  :formatter="hasValidFormacouponType" ></el-table-column>
        <!-- <el-table-column  prop="effectiveType"  label="生效方式" align="center"  :formatter="hasValidFormaType" ></el-table-column> -->
        <el-table-column  prop="effectiveTime"  label="生效时间(分钟)"  align="center"  min-width="130" ></el-table-column>
        <el-table-column  prop="effectiveDate"  label="生效时间" min-width="160"  align="center" ></el-table-column>
        <el-table-column prop="expiryDate" label="失效时间" min-width="160"  align="center" ></el-table-column>
              <el-table-column label="操作" min-width="290" align="center" fixed="right">
                  <template #default="scope">
                      <el-button
                    icon="el-icon-share"
                    type="text"
                    @click="handleInterlinkage(scope.row)"
                    >链接</el-button>
                    <el-button
                    type="text"
                    icon="Edit"
                    @click="handleEdit(scope.row)"
                    v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:update`])"
                    >编辑</el-button>
                    <el-button
                        type="text"
                        icon="Check"
                        @click="handleClickDetails(scope.row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:get`])"
                    >查看详情</el-button>
                    <el-button
                        type="text"
                        icon="Delete"
                        class="red"
                        @click="handleClickDelete(scope.row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salescoupon:softdelete`])"
                    >删除</el-button>
                  </template>
                </el-table-column>
      </el-table>
      <!-- 分页 begin -->
      <EupPagination
        :current-page="state.pageIndex"
        :pagesizes="[10, 20, 50, 100]"
        :pagesize="state.pageSize"
        :total="state.pageTotal"
        @getPageData="datasource"
        @resPageData="resPageData"
      >
      </EupPagination>
      <!-- 分页 end -->
    </div>
    <!-- 内容区域 end -->
    <!-- 链接 -->
    <el-dialog
      title="优惠券链接"
      v-model="state.centerDialogVisible"
      width="30%"
      @close="closeDialogInterlinkage"
    >
      <el-form
        ref="refAddForm"
        :model="state.Interlinkage"
        label-width="80px"
        :inline="false"
      >
        <el-form-item label="优惠券" prop="Interlinkage">
          <el-input
            id="form_tone"
            v-model="state.Interlinkage.Interlinkage"
            autocomplete="off"
            readonly
          />
        </el-form-item>
        <!-- </el-col> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialogInterlinkage()">取 消</el-button>
          <el-button type="primary" @click="copySave()">复 制</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加/编辑窗口 begin -->
    <el-dialog
      :title="state.dialogTitle"
      v-model="state.addDialogFormVisible"
      width="60%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        ref="refAddForm"
        :model="state.addForm"
        label-width="80px"
        :inline="false"
      >
        <el-row>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="名称" prop="couponName">
              <el-input
                v-model="state.addForm.couponName"
                autocomplete="off"
                :disabled="state.eidt"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="状态" prop="isActive">
              <el-select v-model="state.addForm.isActive" placeholder="请选择">
                <el-option
                  v-for="item in state.opbannerType"
                  :key="item.isActive"
                  :label="item.label"
                  :value="item.isActive"
                ></el-option
              ></el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="生效类型" prop="isActive">
              <el-select
                v-model="state.addForm.effectiveType"
                placeholder="请选择"
                :disabled="state.eidt"
                @change="minutesstoder"
              >
                <el-option
                  v-for="item in state.opisActive"
                  :key="item.effectiveType"
                  :label="item.label"
                  :value="item.effectiveType"
                ></el-option
              ></el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="state.TimeRange">
   <!--          <el-form-item label="时间选择" prop="remark">
              <el-date-picker
                v-model="state.time"
                :disabled="state.eidt"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item> -->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
         <el-form-item label="时间选择" prop="redPackAmount">
                <el-date-picker
                v-model="state.time"
                :disabled="state.eidt"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
                </el-form-item>
            </el-col>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="类型" prop="couponType">
              <el-select
                v-model="state.addForm.couponType"
                placeholder="请选择"
                :disabled="state.eidt"
              >
                <el-option
                  v-for="item in state.couponTypees"
                  :key="item.couponType"
                  :label="item.label"
                  :value="item.couponType"
                ></el-option
              ></el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="8"
            :lg="8"
            :xl="6"
            v-if="state.minutes"
          >
            <el-form-item label="生效时间" prop="effectiveTime">
              <el-input
                autocomplete="off"
                placeholder="分钟"
                v-model.trim="state.addForm.effectiveTime"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                :disabled="state.eidt"
              >
                <template #append>分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="优惠条件" prop="couponCondition">
              <el-input
                autocomplete="off"
                v-model.trim="state.addForm.couponCondition"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                :disabled="state.eidt"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="优惠金额" prop="couponContent">
              <el-input
                autocomplete="off"
                v-model.trim="state.addForm.couponContent"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                :disabled="state.eidt"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="发行数量" prop="publishCount">
              <el-input
                autocomplete="off"
                v-model.trim="state.addForm.publishCount"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="限领数量" prop="limitCount">
              <el-input
                autocomplete="off"
                v-model.trim="state.addForm.limitCount"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="可领数量" prop="remainCount">
              <el-input
                autocomplete="off"
                v-model.trim="state.addForm.remainCount"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                :disabled="state.addti"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="state.addForm.remark" autocomplete="off" />
            </el-form-item>
          </el-col>
          <!-- </el-col> -->
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog()">取 消</el-button>
          <el-button type="primary" @click="addDialogFormSave(this)"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 添加/编辑窗口 end -->
    <!-- 详情弹出框 -->
    <el-dialog title="优惠券详情" v-model="state.detailsVisible" width="60%" :close-on-click-modal="false" >
      <el-form
        ref="form"
        :model="state.particulars"
        label-width="80px"
        :inline="false"
      >
        <el-row>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="名称" prop="couponName">
              <el-input
                v-model="state.particulars.couponName"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="状态" prop="isActive">
              <el-input
                v-model="state.particulars.isActive"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="类型" prop="couponType">
              <el-input
                v-model="state.particulars.couponType"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="条件" prop="couponCondition">
              <el-input
                v-model="state.particulars.couponCondition"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="优惠金额" prop="couponContent">
              <el-input
                v-model="state.particulars.couponContent"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="生效方式" prop="effectiveType">
              <el-input
                v-model="state.particulars.effectiveType"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="生效时间" prop="effectiveDate">
              <el-input
                v-model="state.particulars.effectiveDate"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="失效时间" prop="expiryDate">
              <el-input
                v-model="state.particulars.expiryDate"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="有效时间" prop="effectiveTime">
              <el-input
                v-model="state.particulars.effectiveTime"
                autocomplete="off"
                :disabled="true"
              >
                <template #append>分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="发行数量" prop="publishCount">
              <el-input
                v-model="state.particulars.publishCount"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="限领数量" prop="limitCount">
              <el-input
                v-model="state.particulars.limitCount"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="可领数量" prop="remainCount">
              <el-input
                v-model="state.particulars.remainCount"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="state.particulars.remark"
                autocomplete="off"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onBeforeMount, onMounted } from "vue";
import EupPagination from "../../../components/EupPagination.vue";
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import {
  GetPage,
  Add,
  Update,
  SoftDelete,
  BatchSoftDelete,
} from "@/serviceApi/Sales/Coupon";
import { ElMessage, ElMessageBox } from "element-plus";
import { elConfirmDialog, formatDate } from "@/common/js/comm";
import EnumConfig from "@/enum/EnumConfig"
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: "",
  components: {
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs
  },
  setup() {
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
      tableData: [],
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      couponName: "",
      pageTotal: 0,
      query: {isActive: null},queryOpisActive: [{isActive: null,label: '全部'},{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
      multipleSelection: [],
      time: [],
      addDialogFormVisible: false,
      dialogTitle: "",
      addForm: {
        couponName: "",
        isActive: "1",
        couponType: "1",
        couponCondition: "",
        couponContent: "",
        effectiveType: "1",
        effectiveDate: "",
        expiryDate: "",
        effectiveTime: 0,
        publishCount: "",
        limitCount: "",
        remainCount: "",
        remark: "",
      },
      addti: true,
      opbannerType: [
        { isActive: "1", label: "有效" },
        { isActive: "2", label: "无效" },
      ],
      opisActive: [
        { effectiveType: "1", label: "时间范围" },
        { effectiveType: "2", label: "领取时间" },
      ],
      TimeRange: false,
      minutes: false,
      couponTypees: [
        { couponType: "1", label: "满减" },
        { couponType: "2", label: "现金券" },
      ],
      particulars: {},
      detailsVisible: false,
      centerDialogVisible: false,
      Interlinkage: { Interlinkage: "" },
    });
    onBeforeMount(() => {});
    onMounted(() => {
      datasource();
    });
    const handleSearch = () => {
      state.pageIndex = 1;
      datasource();
    };
    const handleClickAddData = () => {
      state.eidt = false;
      state.addDialogFormVisible = true;
      state.dialogTitle = "新增优惠券配置";
      state.addForm.add = "add";
      state.time = [];
      minutesstoder(state.addForm.effectiveType);
    };
    const handleClickBatchDelete = () => {
      if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          BatchSoftDelete(state.multipleSelection)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                datasource();
              } else {
                ElMessage.error("删除失败！");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除！");
        }
      );
    };
    const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.couponId;
      });
    };
    const handleEdit = (row) => {
      state.addDialogFormVisible = true;
      var strJson = JSON.stringify(row);
      var jsonRow = JSON.parse(strJson);
      minutesstoder(jsonRow.effectiveType);
      state.dialogTitle = "编辑优惠券配置";
      state.addForm.eit = "eit";
      state.addForm = {
        couponId: jsonRow.couponId,
        couponName: jsonRow.couponName,
        isActive: jsonRow.isActive + "",
        couponType: jsonRow.couponType + "",
        couponCondition: jsonRow.couponCondition,
        couponContent: jsonRow.couponContent,
        effectiveType: jsonRow.effectiveType + "",
        effectiveTime: jsonRow.effectiveTime,
        publishCount: jsonRow.publishCount,
        limitCount: jsonRow.limitCount,
        remainCount: jsonRow.remainCount,
        remark: jsonRow.remark,
      };
      state.time = [jsonRow.effectiveDate, jsonRow.expiryDate];
      state.eidt = true;
    };
    const handleClickDetails = (row) => {
      state.detailsVisible = true;
      var strJson = JSON.stringify(row);
      state.particulars = JSON.parse(strJson);
      state.particulars.effectiveType = hasValidFormacouponType(row);
      state.particulars.couponType = hasValidFormaType(row);
      state.particulars.isActive = hasValidFormaStatus(row);
    };
    const handleClickDelete = (row) => {
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          SoftDelete(row.couponId)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                datasource();
              } else {
                ElMessage.error("删除失败！");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除！");
        }
      );
    };
    const datasource = () => {
      var parems = {
        currentPage: state.pageIndex,
        pageSize: state.pageSize,
          "filter.couponName": state.couponName,
          "filter.IsActive": state.query.isActive,
          "filter.couponType": null,
          "filter.effectiveType": null,
      };
      GetPage(parems).then((res) => {
        if (res.code == 1) {
          state.pageTotal = res.data.total; //初始化列表数据总数
          state.tableData = res.data.list;
          //添加num序号字段
          state.tableData.forEach((data, i) => {
            data.num = i + 1;
          });
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const resPageData = (obj) => {
      state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    };
    const hasValidFormaStatus = (row) => {
      if (row.isActive == 1) {
        return " 有效";
      } else if (row.isActive == 2) {
        return " 无效";
      } else {
        return "全部";
      }
    };
    const hasValidFormaType = (row) => {
      if (row.effectiveType == 1) {
        return "时间范围";
      } else if (row.effectiveType == 2) {
        return " 领取时间";
      } else {
        return "全部";
      }
    };
    const hasValidFormacouponType = (row) => {
      if (row.couponType == 1) {
        return "满减";
      } else if (row.couponType == 2) {
        return " 现金券";
      } else {
        return "全部";
      }
    };
    const closeDialog = () => {
      state.addDialogFormVisible = false;
      state.time = [];
      state.addForm = {
        couponName: "",
        isActive: "1",
        couponType: "1",
        couponCondition: "",
        couponContent: "",
        effectiveType: "1",
        effectiveDate: "",
        expiryDate: "",
        effectiveTime: 0,
        publishCount: "",
        limitCount: "",
        remainCount: "",
        remark: "",
      };
    };
    const minutesstoder = (val) => {
      if (val == 1) {
        state.minutes = false;
        state.TimeRange = true;
      } else {
        state.minutes = true;
        state.TimeRange = false;
      }
    };
    const addDialogFormSave = () => {
      var params = {};
      if (state.dialogTitle == "新增优惠券配置" && state.addForm.add == "add") {
        params = {
          couponName: state.addForm.couponName,
          isActive: state.addForm.isActive,
          couponType: state.addForm.couponType,
          couponCondition: state.addForm.couponCondition,
          couponContent: state.addForm.couponContent,
          effectiveType: state.addForm.effectiveType,
          effectiveDate: formatDate(state.time[0]),
          expiryDate: formatDate(state.time[1]),
          effectiveTime: state.addForm.effectiveTime,
          publishCount: state.addForm.publishCount,
          limitCount: state.addForm.limitCount,
          remark: state.addForm.remark,
        };

        if (params.effectiveType == 1) {
          if (
            params.effectiveDate == "NaN-NaN-NaN NaN:NaN:NaN" ||
            params.expiryDate == "NaN-NaN-NaN NaN:NaN:NaN"
          ) {
            return ElMessage.error("请选择日期");
          }
        } else {
          params.effectiveDate = "";
          params.expiryDate = "";
        }
        if (params.publishCount == "") {
          return ElMessage.error("请完善信息");
        }
        if (params.limitCount == "") {
          return ElMessage.error("请完善信息");
        }
        /*             if( params.effectiveTime=="")
            {
               return ElMessage.error("请完善信息");
            } */
        if (params.couponName == "") {
          return ElMessage.error("请完善信息");
        }
        if (params.couponCondition == "") {
          return ElMessage.error("请完善信息");
        }
        if (params.couponContent == "") {
          return ElMessage.error("请完善信息");
        }
        Add(params)
          .then((res) => {
            if (res.code == "1") {
              ElMessage.success("添加成功");
              datasource();
              state.addDialogFormVisible = false;
              state.time = [];
            } else {
              ElMessage.error(res.msg);
            }
          })
          .catch((err) => {
            ElMessage.error(err.msg);
          });
      } else {
        params = {
          couponId: state.addForm.couponId,
          publishCount: state.addForm.publishCount,
          limitCount: state.addForm.limitCount,
          isActive: state.addForm.isActive,
          remark: state.addForm.remark,
        };
        if (params.publishCount == "") {
          return ElMessage.error("请完善信息");
        }
        if (params.limitCount == "") {
          return ElMessage.error("请完善信息");
        }
        /*  if( params.effectiveTime=="")
            {
               return ElMessage.error("请完善信息");
            } */
        if (params.couponName == "") {
          return ElMessage.error("请完善信息");
        }
        if (params.couponCondition == "") {
          return ElMessage.error("请完善信息");
        }
        if (params.couponContent == "") {
          return ElMessage.error("请完善信息");
        }
        Update(params)
          .then((res) => {
            if (res.code == 1) {
              ElMessage.success("编辑成功");
             state.addDialogFormVisible = false;
              state.time = [];
            } else {
              ElMessage.error(res.msg);
            }
          })
          .catch((err) => {
            ElMessage.error(err.msg);
          });
      }
    };
    const handleInterlinkage = (row) => {
      state.centerDialogVisible = true;
      state.Interlinkage.Interlinkage =
        process.env.VUE_APP_BASE_localhost +
        "/Sales/GetDeduction?cid=" +
        row.couponId;
    };
    const closeDialogInterlinkage = () => {
      state.centerDialogVisible = false;
    };
    const copySave = () => {
      var copycode = document.getElementById("form_tone");
      copycode.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      ElMessage.success("复制成功");
      state.centerDialogVisible = false;
    };
    return {
      state,
      handleSearch,
      handleClickAddData,
      handleClickBatchDelete,
      handleSelectionChange,
      handleEdit,
      handleClickDetails,
      addDialogFormSave,
      handleClickDelete,
      datasource,
      resPageData,
      hasValidFormaStatus,
      hasValidFormaType,
      hasValidFormacouponType,
      closeDialog,
      minutesstoder,
      handleInterlinkage,
      closeDialogInterlinkage,
      copySave,
    };
  },
};
</script>
<style scoped></style>
